import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import dateFormatter from '../../helpers/date-formatter';
import './style.scss';

const PostListing = (props) => {
  const {
    category,
    data: {
      node: {
        html,
        fields: {
          slug,
        },
        frontmatter: {
          type,
          title,
          date,
          description,
          featuredImage,
        },
      },
    },
  } = props;

  return (
    <div className="post">
      {featuredImage && (
        <Link className="post__title-link" to={slug} aria-label={`Link to post: ${title}`}>
          <Img fluid={featuredImage.childImageSharp.fluid} className="post__featuredImage" />
        </Link>
      )}
      <h2 className="post__title">
        {type !== 'question' && (
          <Link className="post__title-link" to={slug}>
            {title}
          </Link>
        )}
        {type === 'question' && (
          <div>
            {title}
          </div>
        )}
      </h2>
      <div className="post__meta">
        <time
          className="post__meta-time"
          dateTime={dateFormatter(date)}
        >
          {dateFormatter(date)}
        </time>
        <span className="post__meta-divider" />
        {category && (
          <span className="post__meta-category">
            <Link to={category.url} className="post__meta-category-link">
              {category.title}
            </Link>
          </span>
        )}
      </div>
      {type !== 'question' && (
        <div>
          <p className="post__description">{description}</p>
          {description && slug && (
            <Link className="post__readmore" to={slug}>
              Read
            </Link>
          )}
        </div>
      )}
      {type === 'question' && (
        <div
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{ __html: html }}
        />
      )}
    </div>
  );
};

export default PostListing;
